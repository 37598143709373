<template>
    <div class="content_container customer_terms">
        <div class="content">
            <div class="banner">
                <h1>금융소비자보호</h1>
            </div>
			<div class="m_terms_menu_wrapper">
				<ul class="terms_menu">
					<li class="current">
						<a @click="toggleMenu">자료열람청구권</a>
					</li>
					<li>
						<router-link to="/customer/financeprotect" @click="toggleMenu">금융소비자보호</router-link>
					</li>
					<li>
						<router-link to="/customer/nego" @click="toggleMenu">금리인하요구권</router-link>
					</li>
					<li>
						<router-link to="/customer/withdraw" @click="toggleMenu">청약철회권</router-link>
					</li>
					<li>
						<router-link to="/customer/termination" @click="toggleMenu">위법계약해지권</router-link>
					</li>
				</ul>
			</div>
            <section class="terms">
				<ul class="terms_menu">
					<li>
						<router-link to="/customer/financeprotect">금융소비자보호</router-link>
					</li>
					<li>
						<router-link to="/customer/nego">금리인하요구권</router-link>
					</li>
					<li>
						<router-link to="/customer/withdraw">청약철회권</router-link>
					</li>
					<li class="current">
						<router-link to="/customer/dataopen">자료열람청구권</router-link>
					</li>
					<li>
						<router-link to="/customer/termination">위법계약해지권</router-link>
					</li>
				</ul>
				<div>
					<div class="text_box"> 
						<div class="csfont2 title" style="color:#000;">자료열람청구권</div>

						<br><br>

						<div class="csfont1">1. 자료열람청구권이란?</div>
						<div class="csfont2">
						- 금융소비자가 법 제36조에 따른 분쟁조정 또는 소송의 수행 등 권리구제를 위한 목적으로 금융상품판매업자등이 기록 및 유지·관리하는 자료의 열람을 요구할 수 있는 권리
						</div>
						<br><br>

						<div class="csfont1">2. 열람을 신청할 수 있는 자료의 종류</div>
						<div class="csfont2">
						가. 가계대출<br>
						① 계약체결·이행에 관한 자료, 금융상품등에 관한 광고 자료<br>
						② 금융소비자의 권리행사에 관한 자료*<br>
						③ 내부통제기준의 제정 및 운영 등에 관한 자료<br>
						④ 업무 위탁에 관한 자료<br>
						* 금융소비자의 자료열람 연기·제한 및 거절에 관한 자료(법 §28 ④ 후단, ⑤), 청약의 철회(법 §46) 및 위법계약의 해지(§47) 관련 자료
						</div>
						<br><br>

						<div class="csfont1">3. 신청 방법</div>
						<div class="csfont2">
						아래 내용을 포함한 자료열람신청서를 작성하여 금융회사에 제출<br>
						① 열람 목적 : 법 제36조에 따른 분쟁조정 신청 내역 또는 소송제기 내역 포함<br>
						② 열람 범위 : 열람하고자 하는 자료의 내용 및 해당 자료와 열람 목적과의 관계 포함<br>
						③ 열람 방법<br>
						- 당사양식은(고객센터>서식자료실)로 가셔서 다운로드 하시면 됩니다.
						</div>
						<br><br>

						<div class="csfont1">4. 처리 프로세스</div>
						<div class="csfont2">
						① 신청서 제출<br>
						② 신청서 접수 및 유관 부서 이관<br>
						③ 사실관계 확인 및 수용 여부 결정·소비자 통보<br><br>

						- 자료열람 신청서 접수일로부터 8일 이내에 처리 결과 안내 예정<br>
						(단, 8일 이내에 열람이 불가능한 정당한 사유가 있을 경우, 금융소비자에게 문서로 사유를 알리고 열람을 연기할 수 있음)<br><br>

						- 열람이 가능할 경우, 당사는 문서·전화·팩스·이메일·휴대전화 문자메시지 등의 방법으로 안내 예정<br>
						※ 열람 시 실비를 기준으로 수수료 또는 우송료 청구 가능
						</div>
						<br><br>

						<div class="csfont1">5. 열람 제한·거절 사유</div>
						<div class="csfont2">
						- 당사는 아래와 같은 사유가 있는 경우 금융소비자에게 문서로 그 사유를 알리고,열람을 제한하거나 거절 가능
						</div>
						<br><br>

						<div class="csfont1">6. 심사결과 통지</div>
						<div class="csfont2">
						1) 법령에 따라 열람을 제한하거나 거절할 수 있는 경우<br>
						2) 다른 사람의 생명·신체를 해칠 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우<br>
						3) 「부정경쟁방지 및 영업비밀보호에 관한 법률」 제2조제2호에 따른 영업비밀을 현저히 침해할 우려가 있는 경우<br>
						4) 개인정보의 공개로 인해 사생활의 비밀 또는 자유를 부당하게 침해할 우려가 있는 경우<br>
						5) 열람하려는 자료가 열람목적과 관련이 없다는 사실이 명백한 경우
						</div>
						<br><br>
					</div>
				</div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name : 'DataOpen',
	methods:{
		toggleMenu(){
			document.querySelector('.m_terms_menu_wrapper .terms_menu').classList.toggle('on');
		},
	}
}
</script>

